/* HEADING FOR BOTH SECTIONS WITH A CAROUSEL */
.carousel-heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 clamp(20px, 3vw, 132px);
    /* margin-left: 2.5rem; */
    /* margin-right: 2.5rem; */
}
.carousel-heading section {
    display: flex;
    align-items: center;
    gap: 0.31rem;
}

.carousel-heading section button {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40px;
    height: 40px;
    color: #00359a;
    border-radius: 50%;
    border: none;
    background-color: white;
    opacity: 1;
    box-shadow: 0 2px 5px #0000001A;
    transition: all .3s ease-in-out 0s;
}

.carousel-heading section button:hover {
    background-color: #00359a;
    color: white;
}

.carousel-heading h2{
    font-size: clamp(29px, 5vw, 34px);
    color: #00359a;
    line-height: 36px;
}

/* TRIP CARDS */
.card-container {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    justify-content: flex-start;
    gap: 1.56rem;
    box-sizing: border-box;
    margin: clamp(20px, 3vw, 132px);
    margin-top: 0;
    padding-bottom: 10px;
    transition: transform 500ms;

    /* height: inherit; */
    /* padding-right: 10px; */
}

/* each card takes up 33%, minus the gap */
.trip-card {
    flex: 0 0 calc((100% / 3) - 20px);
    border-radius: 19px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    background-color: white;
    scroll-snap-align: start;
    height: inherit;
}


/* card img section */
.card-image {
    z-index: 1;
    position: relative;
    /* min-width: 405px; */
    overflow: hidden;
    padding-top: 66%;
    /* min-height: 271px; */
    /* width: 318px; */
    height: max-content;
}


.card-image img::before, .card-image::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    pointer-events: none;
    max-height: 8.62rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000 100%);
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    /* height: auto; */
    object-fit: cover;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    z-index: 2;
}

.card-image img {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}

/* date tile */
.date-tile {
    border: 1px solid #00359a;
    position: absolute;
    bottom: 1.25rem;
    left: 0.93rem;
    border-radius: 5px;
    width: 80px;
    text-align: center;
    color: white;
    z-index: 3;
}

.date-day {
    border-radius: 5px;
    /* line-height: 52px; */
    background-color: rgba(255,255,255,.23);
    -webkit-backdrop-filter: blur(3.4px);
    backdrop-filter: blur(3.4px);
    font-size: clamp(6vh, 2.45rem, 7vh);
    line-height: 1.3;
    font-weight: bold;
}

.date-month {
    background-color: #00359a;
    font-weight: 700;
    line-height: 2rem;
    font-size: clamp(2.5vh, 1.125rem, 3.1vh);
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* vagabondo doc info */
.vagabondodoc {
    position: absolute;
    bottom: 1rem;
    right: 0.62rem;
    top: auto;
    display: flex;
    align-items: center;
    color: white;
    flex-direction: row-reverse;
    column-gap: 10px;
    z-index: 3;
}

.vagabondodoc a {
    width: 50px !important;
    height: 50px !important;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    /* position: relative; */
    font-size: clamp(14px, 5vw, 14px);
    /* line-height: 0; */
    font-weight: 700;
    text-decoration: none;
    color: white;
}

.vagabondodoc p {
    font-size: clamp(12px, 5vw, 12px);
    line-height: 0.2;
    text-align: right;
}

.vagabondodoc img {
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    /* margin-right: 5px; */
    /* margin-left: 0.62rem; */
}

/* trip style icon */
.trip-style-icon {
    top: 0.62rem;
    left: 0.62rem;
    position: absolute;
}

.trip-style-icon img {
    width: 40px;
    height: 40px;
}

.user-count {
    top: 0.62rem;
    right: 0.62rem;
    position: absolute;
    background-color: #8a00c2;
    border-radius: 1.25rem;
    padding: 8px 15px 8px 35px;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.user-count p {
    margin: 0;
    font-size: clamp(12px, 5vw, 12px);
    white-space: nowrap;
    line-height: 13px;
}

.user-count svg {
    width: 20px;
    height: 20px;
    background-color: white;
    color: #8a00c2;
    border-radius: 50%;
    padding: 2px;
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
}

.user-count::before {
    content: "";
    display: block;
    width: 29px;
    height: 29px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 1px; /* match with svg position */
    transform: translateY(-50%);
    outline: 1px solid #9632c8; /* the outline for the fire icon */
}

/* trip info section */
.trip-info {
    padding: 1.89rem;
}

.trip-details {
    /* padding-bottom: 0.62rem; */
    border-bottom: 2px solid #ddd;
}

.trip-title {
    border-bottom: 2px solid #ddd;
    /* border-width: 2px; */
    /* border-width: 2px; */
    min-height: 4.75rem;
    line-height: 1.2;
    /* border-bottom-style: ridge; */
    /* border-bottom: inherit; */
    margin-top: 0;
    font-size: clamp(30px, 5vw, 30px);
    font-weight: 700;
    color: #00359a;
    margin-bottom: 5px;
}

.trip-title a {
    text-decoration: none;
    color: #00359a;
}
.trip-metadata {
    margin-bottom: 0;
    list-style: none;
    padding: 10px 0;
    /* margin: 0; */
}

.trip-metadata li {
    /* gap: 5px; */
    /* margin-top: 5px; */
    /* margin-bottom: 10px; */
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    font-size: clamp(16px, 5vw, 16px);
    padding: 0;
    line-height: 19px;
}

.trip-metadata li:last-child {
    /* margin-bottom: 0; */
    padding: 0;
    margin-bottom: 0;
}

.trip-status .value {
    color: #28c83c;
    font-size: clamp(16px, 5vw, 16px);
    line-height: 1.5rem; /* this should match the height of the icon for alignment */
    font-weight: 700;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    /* margin-bottom: 10px; */
}

.trip-status .value::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 5px;

    /* in this case I was able to use a background-image with the url of the SVG icon */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16"%3E%3Cpath d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"%3E%3C/path%3E%3C/svg%3E');
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;

    /* Circle around the icon */
    background-color: #28c83c;
    border-radius: 50%;
    /* padding-right: 2px; */
    vertical-align: middle; /* aligns the icon with the text */
}



.trip-duration .label, .trip-registrants .label {
    font-size: clamp(16px, 5vw, 16px);
    line-height: 19px;
    font-weight: 650;
}

/* trip pricing section */
.trip-pricing {
    padding: 10px 0 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0;
}

.price {
    margin-top: 0;
    width: 50%; /* each price takes 50% of the width */
    display: block;
    text-align: left;
}

/* label styling */
.price .label {
    color: #888;
    font-size: clamp(14px, 5vw, 14px);
    line-height: 16px;
    font-weight: 400;
    display: block; /* to make sure label is on top */
    /* margin-bottom: 5px; */ /* space between label and value */
}

/* currency and value styling/alignment */
.price .currency {
    margin-right: 0.62rem;
    font-weight: 700;
    margin-top: 3.12rem;
    font-size: clamp(2.44vh, 1.125rem, 3.1vh);
    line-height: 2rem;
}

.price span {
    font-size: clamp(26px, 5vw, 26px);
    line-height: 2.93rem;
    font-weight: 700;
    display: inline-block;
}

/* buttons container, could possibly be removed */
.btn-container {
    display: flex;
    flex-direction: column;
    gap: 0.95rem;
    margin-top: 0.62rem;
}

.btn {
    text-align: center;
    padding: 15px 10px 14px;
    font-size: clamp(16px, 5vw, 16px);
    font-weight: 700;
    line-height: 1.8rem;
    border-radius: 5px;
    border: 1px solid #00359a;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}


.btn.outline {
    background-color: white;
    color: #00359a;
    transition: background-color 0.3s, color 0.3s;
}

.btn.primary.outline:hover {
    background-color: #00359a;
    color: white;
}

.btn.filled {
    background-color: #00359a;
    color: white;
}

.btn:hover {
    background-color: white;
    color: #00359a;
}

.warning-box {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #00359a;
    background-color: gold;
    padding: 20px;
    border-radius: 0 0 20px 20px;
    z-index: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.warning-box::before {
    content: "";
    height: 25px;
    width: 100%;
    position: absolute;
    top: -25px;
    left: 0;
    background-color: gold;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/* positioning of the warning box to make it fit inside the card */
.card-container .warning-box {
    /* margin-top: -20px; */
    padding-top: 0;
}

.warning-box p {
    margin-top: 20px;
    margin-bottom: 0;
    /* margin: 0; */
    text-align: left;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.trip-card:has(.warning-box) .card-image {
    position: relative;
    overflow: hidden;
    padding-top: 51.5%;
    /* min-height: 210px; */ /* this is the adjusted height when the warning box is present */
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

/* medium screens (2 cards) */
@media screen and (max-width: 1024px) {
    .trip-card {
        flex: 0 0 calc((100% / 2) - 10px);
    }
}

/* small screens (1 card) */
@media screen and (max-width: 768px) {
    .trip-card {
        flex: 0 0 calc((100%) - 5px);
    }

    .date-tile {
        bottom: 2rem;
        left: 1.9rem;
    }

    .vagabondodoc {
        bottom: 2rem;
        right: 1.9rem;
    }

    .trip-style-icon {
        top: 1.55rem;
        left: 1.9rem;
    }

    .user-count {
        top: 2rem;
        right: 1.9rem;
        padding: 8px 15px 8px 35px;
        line-height: 13px;
    }
}