/* section container */
.storie-container {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    margin-top: 1.25rem;
    background-color: #ff6500;
    border-radius: 2.5rem;
    /* padding-bottom: 60px; */
    margin-bottom: 60px;
}

/* general section styling */
.storie-section {
    display: grid;
    margin: 0 auto;
    padding: 2.5rem 1.25rem;
    background-color: #ff6500;
    border-radius: 2.5rem;
    max-width: 78.13rem;
}

.storie-header {
    color: white;
    margin-bottom: 4rem;
}

.storie-header h2 {
    margin: 0;
    line-height: 1.1;
    font-size: clamp(30px, 5vw, 30px);
    font-weight: bold;
    padding-top: 2.5rem;
}

.storie-header p {
    margin: 0;
    font-size: clamp(25px, 5vw, 25px);
    font-weight: 400;
}

.storie-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 0.75rem;

}

.storie-large {
    grid-column: 1;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
}

.storie-small {
    grid-column: 2;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;
}

/* stories item styling */
.storie-item {
    position: relative;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    border-radius: 1.25rem;
    background-color: black;
}

/* image */
.storie-link img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.25rem;
    transition: transform 0.3s ease-in-out;
}

.storie-link::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
}

.storie-item:hover .storie-link img {
    transform: scale(1.1); /* zoom effect on hover */
}

/* content overlay */
.storie-content {
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 1.25rem 1.25rem;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
    color: white;
}

/* badge container */
.storie-badge {
    margin-top: 0;
    background-color: white;
    color: #00359a;
    padding: .25em 1.8em .25em .25em;
    font-size: clamp(1.9vh, 0.875rem, 2.5vh);
    margin-bottom: 1em;
    font-weight: bold;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    max-width: fit-content;
}

/* badge icon */
.storie-badge img {
    width: 30px;
    height: 30px;
    background-color: #b3d1db;
    /* background-size: 30px; */
    /* padding: 0.3rem; */
    border-radius: 50%;
}

/* badge text */
.storie-badge span {
    font-size: 1rem;
    color: #00359a;
}

/* title and author */
.storie-title {
    margin-top: 0;
    color: white;
    line-height: 1.1;
    font-size: clamp(34px, 2rem, 34px);
    font-weight: 100;
    margin-bottom: 0.25rem;
    overflow: hidden;
}

.storie-title a {
    margin-bottom: 0.25rem;
    text-decoration: none;
    color: white;
}

.storie-author {
    font-size: clamp(2.3vh, 1.125rem, 3.1vh);
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
}

.storie-author a {
    line-height: 1;
    color: white;
    text-decoration: none;
    font-weight: 700;
}

/* media query for stacking */
@media (max-width: 1000px) {
    .storie-grid {
        grid-template-columns: 1fr;
    }

    .storie-large, .storie-small {
        grid-column: 1;
        grid-template-columns: 1fr;
    }

    .storie-container {
        padding-left: 0;
        padding-right: 0;
    }

    .storie-item {
        padding-top: 124%;
    }
}