/* M: It's interesting to make a separate css for mobile conf but then let's do it also for the header menu */


/* hide checkboxes */
.accordion-checkbox {
    display: none;
}

@media screen and (min-width: 1001px) {
    /* hide icon in accordion headers on larger screens */
    .accordion-header svg {
        display: none;
    }
}

/* mobile-specific styles */
@media screen and (max-width: 1000px) {

    p {
        font-size: clamp(2.2vh, 1rem, 2.8vh);
    }

    .row-border {
        display: none;
    }

    #bottom-footer > div:first-child {
        grid-column: 1 / 5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2.5rem; /* 40px */
        gap: 1rem;
        padding-bottom: 1.25rem; /* 20px */
        border-bottom: 0.063rem solid #ddd; /* 1px */
    }

    #bottom-footer {
        display: block;
        padding: 1.5rem;
    }

    #bottom-footer ul {
        margin-top: 0.75rem;
    }

    #bottom-footer ul li {
        margin-bottom: 0.5rem;
    }

    .footer-section {
        padding: 0;
    }

    /* accordion checkboxes */
    .accordion-checkbox {
        display: none;
    }

    /* accordion headers */
    .accordion-header {
        font-size: clamp(1.25rem, 2.5vw, 1.5rem);
        font-weight: bold;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #00359a;
        border-bottom: 0.063rem solid #ddd;
        padding: 0 !important;
    }

    #bottom-footer img {
        width: clamp(185px, 20vw, 260px);
        height: auto;
    }

    /* svg positioning in accordion header */
    .accordion-header svg {
        position: absolute;
        margin-left: auto;
        right: 1.625rem;
        margin-bottom: 0.625rem;
    }

    .accordion-section:focus-within .accordion-header,
    .accordion-checkbox:checked + .accordion-header {
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
        border-color: rgba(13, 110, 253, .5);
        border-radius: .25rem;
    }

    /* accordion arrow rotation */
    .accordion-checkbox:checked + .accordion-header svg {
        transform: rotate(180deg);
        transition: all 0.5s ease;
    }

    /* hide accordion content by default */
    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
        padding: 0;
    }

    /* show accordion content when checkbox is checked */
    .accordion-checkbox:checked ~ .accordion-content {
        max-height: 16rem; /* 500px */
    }

    /* specific max-height for accordion content in paesi section */
    #paesi .accordion-checkbox:checked ~ .accordion-content {
        max-height: 29rem;
        transition: max-height .7s ease-in-out;
    }


    /* show accordion links in mobile */
    .accordion-header a {
        display: block;
        width: 100%;
        color: #00359a;
        text-decoration: none;
    }

    #social-media h2 {
        display: none;
    }

    #bottom-footer h2 {
        color: #00359a;
        font-size: clamp(18px, 5vw, 18px);
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }

    #paesi {
        border-bottom: 0;
    }

    #paesi ul {
        grid-template-columns: 1fr;
        padding: 0;
    }

    /* social media section */
    #social-media {
        justify-content: center;
        /* margin-top: 1.5rem; */
    }

    #contatti address, #contatti p, #bottom-footer a {
        font-style: normal;
        font-size: clamp(16px, 5vw, 16px);
    }

    /* copyright and privacy policy centering */
    #copyright {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    #copyright h2,
    #copyright p:first-of-type {
        display: inline;
        font-size: 1.2rem;
        font-weight: normal;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        color: black;
        margin-bottom: -0.625rem;
    }

    #copyright p:last-of-type {
        margin-top: 0.625rem;
        font-size: 1.2rem;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    #bottom-links {
        justify-content: center;
        text-align: center;
        padding-top: 0.5rem;
    }

    #bottom-links a {
        font-size: 1rem;
    }

    /* position for the "back to top" button */
    .footer-button {
        position: relative;
        margin-top: 1.25rem;
        display: block;
        text-align: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    #azienda {
        border-top: 0.063rem solid #ddd;
        margin: 0;
    }

    #paesi {
        border-bottom: none;
        margin: 0;
    }
}
