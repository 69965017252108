/* Shared styles for h2 in fixed CTA sections */
.cta-fixed-title {
    font-size: 2.12rem;
    margin-bottom: 0.25em;
    line-height: 1;
}

/* Shared styles for p in fixed CTA sections */
.cta-fixed-paragraph {
    margin-bottom: 1rem;
    margin-top: 0;
}

.third-bold {
    font-weight: bold;
}

/* Shared styles for links in fixed CTA sections */
.cta-fixed-button {
    font-size: clamp(18px, 2rem, 18px);
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    background: #00359a;
    color: white;
    text-decoration: none;
    border: 1px solid #00359a;
    border-radius: 0.31rem;
    font-weight: bold;
    margin-top: auto;
    transition: background-color 0.3s, color 0.3s;
}

.cta-fixed-button svg {
    margin-left: auto;
    vertical-align: middle;
    flex-shrink: 0;
}

/* Hover effects for links in fixed CTA sections */
.cta-fixed-button:hover {
    background: white;
    color: #00359a;
}

/* Shared styles for img in fixed CTA sections */
.cta-fixed-img {
    height: auto;
    width: 16.8rem;
    max-width: 26rem;
    object-fit: contain;
    border-radius: 1.25rem;
}

/* Shared styles for picture in fixed CTA sections */
.cta-fixed-picture {
    position: absolute;
    bottom: -0.65rem;
    right: 0;
    width: auto;
    height: auto;
}

/* Shared styles for svg inside links in fixed CTA sections */
.cta-fixed-link svg {
    margin-left: 0.5rem;
    vertical-align: middle;
}


/* shared for the two sec. container */
#cta-twosection-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(clamp(45%,(980px - 100vw)* 1000, 100%), 1fr));
    gap: 1.5rem;
    margin: clamp(20px, 3vw, 132px);
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
}

/* layout styles for the CTAs Compagni di viaggio and Newsletter */
.cta-twosection {
    display: grid;
    grid-template-columns: 55% 1fr;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 1.25rem;
    position: relative;
    margin-bottom: 1.5rem;
}

/* flex for text content in cta sections */
.cta-twosection div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #00359a;
    margin: 0;
    z-index: 2;
}

.cta-onesection-container {

    margin: 0 clamp(20px, 3vw, 132px);
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
    margin-bottom: 4rem;
}

.cta-onesection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-radius: 1.25rem;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
}

/* header inside the CTA viaggia con noi */
.cta-onesection header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #00359a;
    margin: 0;
    z-index: 2;
    max-width: 60%; /* this is to limit text width and therefore allow wrapping */
    word-wrap: break-word;
}

.cta-onesection img {
    height: auto;
    width: 23rem;
    max-width: none;
    object-fit: contain;
    border-radius: 1.25rem;
    z-index: 1;
    position: relative;
}

/* in this case, both the image and the button are handled in a different way
compared to the fixed buttons in the two-section CTA,
therefore they don't share the same class.
 */
.cta-onesection a {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: #00359a;
    color: white;
    text-decoration: none;
    border: 1px solid #00359a;
    border-radius: 0.31rem;
    font-weight: bold;
    margin-top: 1rem;
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
    z-index: 3;
    transition: background-color 0.3s, color 0.3s;
}

.cta-onesection svg {
    margin-left: 0.5rem;
    vertical-align: middle;
}

/* COMPONENTS FOR CTA's WITH ONLY AN IMAGE AS BACKGROUND */

.cta-image-background {
    /* min-height: 19.8rem; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-left: 2.5rem; */
    margin: clamp(20px, 3vw, 132px);
    /* margin-right: 2.5rem; */
    position: relative;
    text-align: left;
    padding: 2.5rem;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    overflow: hidden;
}

.cta-image-background h2 {
    /* margin-top: 0; */
    font-weight: 400;
    font-size: 3.75rem;
    color: white;
    /* margin-bottom: 1rem; */
    z-index: 2;
    position: relative;
}

.cta-image-background a {
    font-size: 2vh;
    line-height: 1.6rem;
    font-weight: 400;
    display: inline-block;
    padding: 10px 12px;
    background: white;
    color: #00359a;
    text-decoration: none;
    border-radius: 0.8rem;
    /* font-weight: bold; */
    z-index: 2;
    transition: background-color 0.3s, color 0.3s;
    border: 1px solid #ddd;
}

.cta-image-background a:hover {
    background: #00359a;
    color: white;
}

.cta-image-background svg {
    margin-left: 0.5rem;
    vertical-align: middle;
}

.cta-image-background img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.cta-image-background h2, .cta-image-background a {
    position: relative;
    z-index: 2;
}


/* COMPONENT FOR CTA's WITH COLOR BG ON THE LEFT AND IMAGE ON THE RIGHT */
.cta-color-left-image-right {
    /* margin-top: 2.5rem; */
    margin: clamp(20px, 3vw, 132px);
    height: 21.87rem; /* fixed height for the container, if it's not there it will get huge with the image */
    /* margin-left: 2.5rem; */
    /* margin-right: 2.5rem; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* to test: grid-template-columns: repeat(auto-fill,minmax(clamp(30%, (980px - 100vw)*1000, 100%),1fr)); */
    align-items: center;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
}

.cta-color-left-image-right div {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
}

.cta-color-left-image-right h2, .cta-two-sections-flex h2 {
    margin-top: 0;
    font-size: 3.75rem;
    font-weight: 100;
    color: white;
    margin-bottom: 2rem;
    position: relative;
    text-align: left;
}

.cta-color-left-image-right a, .cta-two-sections-flex a {
    padding: .25em 1em;
    background: white;
    margin-bottom: .5rem;
    color: #00359a;
    text-decoration: none;
    border-radius: 0.62rem;
    line-height: 1.5;
    font-weight: 400;
    font-size: clamp(18px, 5vw, 18px);
    z-index: 2;
    border: 1px solid #ddd;
    transition: background-color 0.3s, color 0.3s;
    align-self: flex-start;
    /* margin-bottom: 1.25rem; */
}

.cta-color-left-image-right a svg,
.cta-two-sections-flex a svg {
    margin-left: 0.5rem;
    vertical-align: middle;
}

.cta-color-left-image-right a:hover,
.cta-two-sections-flex a:hover {
    background: #00359a;
    color: white;
}


.cta-color-left-image-right picture {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

.cta-color-left-image-right img {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 2.5rem;
    max-width: 100%;
    max-height: 21.87rem;
    object-fit: cover;
    /* border-top-right-radius: 2.5rem; */
    border-bottom-right-radius: 2.5rem;
}

/* COMPONENTS WITH 2 CTA's ON THE SAME ROW DISPLAYED AS A REVERSED COLUMN */
#two-sections-flex {
    /* flex-basis: clamp(20ch, 33.333%, 100%); */
    display: flex;
    gap: 2rem;
    margin: clamp(20px, 3vw, 132px);
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Individual CTA Sections */
.cta-two-sections-flex {
    flex: 1 1 clamp(500px, 48%, 100%);
    /* flex-basis: clamp(10ch, 48%, 100%); */
    /* flex: 1 1 48%; */ /* Set flexible basis (48%) and allow growth and shrink */
    display: flex;
    flex-direction: column-reverse; /* image on top and text at the bottom */
    position: relative;
    border-radius: 2.5rem;
    overflow: hidden;
    min-width: 18.75rem; /* this width is set to prevent sections from becoming too small */
}

/* text and button Container */
.cta-two-sections-flex div {
    position: absolute;
    bottom: 0;
    padding: 2.5rem;
    border-radius: 2.5rem;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
}

.cta-two-sections-flex picture {
    padding-top: 70%; /* Control the height ratio of the image container */
    position: relative;
    z-index: 1; /* so the image goes behind the text */
}

.cta-two-sections-flex img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2.5rem;
}
