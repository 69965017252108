/* comment card container */
.comments-container {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    gap: 1.56rem;
    padding-bottom: 2.5rem;
    box-sizing: border-box;
    margin: 0 clamp(20px, 3vw, 132px);
    transition: ease-in-out 0.3s;
}


/* individual comment */
.comment {
    /* in this case,
     we have to account both the internal padding (of the comment)
     and the external padding (of the container) */
    flex: 0 0 calc((100% / 3) - 1.56rem - 2.5rem);
    border-radius: 2.13rem;
    box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
    border: 4px solid #4096f7;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    padding: 1.25rem;
    height: auto;
    transition: transform 0.3s;
    justify-content: space-between;
}

/* header for the comment (contains avatar and user details) */
.comment-header {
    display: flex;
    /* grid-template-columns: auto 1fr; */
    column-gap: 1.38rem;
    margin-bottom: 1.25rem;
}

.comment-author {
    position: relative;
    /* display: inline-block; */
}

/* avatar image */
.comment-author .avatar-img {
    width: 5.38rem;
    height: 5.38rem;
    border-radius: 50%;
    position: relative;
}

/* user badge positioning */

.user-badge {
    position: absolute;
    width: 5.75rem;
    height: 100%;
    left: 3.94rem;
    top: 3.94rem;
    transform: translate(-70%, -70%);
    pointer-events: none;
}

/* user badge image */
.user-badge img {
    width: 100%;
    height: 100%;
}

/* comment details */
.comment-details {
    padding: 0;
    line-height: 1.1;
    white-space: normal;
    font-size: 0.88rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.author-nickname {
    margin-bottom: 0.16rem;
    font-size: clamp(19px, 5vw, 19px);
    font-weight: bold;
    color: #000;
}

/* username link style */
.author-nickname a {
    color: #000;
    text-decoration: none;
}

.author-nickname a:visited,
.author-nickname a:hover {
    color: #000;
}

/* trip link style */
.commented-trip {
    font-size: clamp(16px, 5vw, 16px);
    color: #00359a;
}

.commented-trip a {
    color: #00359a;
    font-weight: bold;
    text-decoration: none;
}

.commented-trip a:visited,
.commented-trip a:hover {
    color: #00359a;
}

/* date in strong tag */
.commented-trip strong {
    display: block;
    margin-top: 0.19rem;
}

/* comment text */
.comment-text {
    font-size: clamp(16px, 5vw, 16px);
    color: #333;
    margin-bottom: 1.25rem;
}

/* discussion button */
.comment-link {
    line-height: 1.5;
    background-color: #0096ff;
    color: white;
    padding: .5em 1em;
    border-radius: 3.13rem;
    text-decoration: none;
    font-size: clamp(18px, 5vw, 18px);
    font-weight: 400;
    text-align: left;
    display: inline-flex;
    width: auto;
    align-items: center;
    justify-content: space-between;
    gap: 0.63rem;
    align-self: flex-start;
    margin: 0;
    transition: all 0.3s ease;
}

/* hover effect */
.comment-link:hover {
    background-color: white;
    color: #0096ff;
    border: 0.06rem solid #0096ff;
}

.comment-link:hover #arrow-icon {
    fill: #0096ff;
}

#arrow-icon {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
}

/* medium screens (2 comments visible) */
@media screen and (max-width: 1024px) {
    .comment {
        flex: 0 0 calc((100% / 2) - 1.56rem - 2.5rem);
    }
}

/* small screens (1 comment visible) */
@media screen and (max-width: 768px) {
    .comment {
        flex: 0 0 calc(100% - 60px);
        flex-direction: column;
        padding: 26px 25px 130px;
        position: relative;
    }

    .user-badge {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        transform: translate(-50%);
        pointer-events: none;
    }

    .comment-author .avatar-img {
        width: 78px;
        height: 78px;
    }

    .comment-header {
        flex-direction: column;
        /* column-gap: 1.38rem; */
        /* margin-bottom: 1.25rem; */
    }

    .comment-link {
        position: absolute;
        margin-top: auto;
        bottom: 2em;
        width: auto;
    }
}
