/* container for the highlights */
.highlights-container {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    gap: 1.90rem;

    box-sizing: border-box;
    margin: 0 clamp(20px, 3vw, 132px);
    padding-bottom: 60px;
    transition: ease-in-out 0.3s;

}

/* individual highlight articles */
.highlight {
    flex: 0 0 calc((100% / 3) - 1.56rem);
    border-radius: 20px;
    box-shadow: 0 0.31rem 0.63rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    transition: transform 0.3s ease;
    height: auto;
    position: relative;
    overflow: hidden;
    background-color: white;
}

/* image handling */
.highlight-image {
    position: relative;
    padding-top: 123%;
    overflow: hidden;
}

.highlight img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

/* image zoom effect */
.highlight:hover img {
    transform: scale(1.1);
}

/* gradient overlay */
.highlight::before {
    content: "";
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

/* info overlay with gradient */
.highlight-info {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 1.25rem 1.25rem;
    color: white;
    z-index: 2;
}

/* title */
.highlight-info h3 {
    font-size: clamp(2.6vh, 1.125rem, 3.1vh);
    font-weight: 700;
    margin: 0 0 1.29rem;
    line-height: normal;
    color: white;
    z-index: 2;
}

/* button */
.button {
    margin-top: 0.63rem;
    padding: 0.5rem 1rem;
    border: 0.06rem solid white;
    background-color: transparent;
    color: white;
    border-radius: 0.31rem;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    transition: background-color 0.3s, color 0.3s;
    font-size: clamp(1.9vh, 0.875rem, 2.5vh);
}

.button #arrow-icon{
    height: 1rem;
    vertical-align: middle;
    margin-left: 4rem;
}

.button:hover {
    background-color: #00359a;
    color: white;
}

/* medium screens (2 highlights visible) */
@media screen and (max-width: 1024px) {
    .highlight {
        flex: 0 0 calc((100% / 2) - 1.56rem);
    }
}

/* small screens (1 highlight visible) */
@media screen and (max-width: 768px) {
    .highlight {
        flex: 0 0 calc(100% - 0rem);
    }
}
