.b-red {
    background-color: red;
}

.b-yellow {
    background-color: gold;
}

.b-green {
    background-color: #28c83c;
}

.b-orange {
    background-color: #ff7d00;
}

.b-jade {
    background-color: #00b46a
}

.b-dark-blue {
    background-color: #00359a;
}

.b-hotpink{
    background-color: #ff4880;
}