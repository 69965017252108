@media screen and (max-width: 1200px) {
.cta-fixed-picture {
    opacity: 30%;
}

.cta-color-left-image-right {
    /* margin-top: 2.5rem; */
    height: auto !important;
    margin: 0 clamp(20px, 3vw, 132px);
    /* margin-left: 2.5rem; */
    /* margin-right: 2.5rem; */
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    align-items: center;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap-reverse;
}

.cta-color-left-image-right div {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
}

.cta-color-left-image-right picture {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: flex-end; */
    /* object-fit: cover; */
    overflow: hidden;
}

.cta-twosection {
    display: flex;
    gap: 1rem;
    /* padding: 1.5rem; */
    border-radius: 1.25rem;
    position: relative;
    margin-bottom: 1.5rem;
}

.cta-fixed-title {
    font-size: clamp(34px, 2rem, 34px);
    margin-bottom: 1rem;
    line-height: 1;
    margin-top: 0;
}

.cta-image-background h2 {
    margin-top: 0;
}

}

@media screen and (max-width: 768px) {
    .cta-onesection {
        display: flex;
        flex-direction: column-reverse;
        border-radius: 2rem;
        padding: 0;
    }

    .cta-onesection .cta-fixed-picture {
        display: flex;
        opacity: 100%;
        position: relative;
    }

    .cta-onesection .cta-fixed-picture img {
        display: flex;
        opacity: 100%;
        position: relative;
        width: 100%;
    }

    .cta-onesection header {
        max-width: 100%;
        margin: 1.9rem;
    }

    .cta-onesection a {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        font-size: clamp(18px, 2rem, 18px);
        position: relative;
        right: 0;
        margin-top: 1.5rem;
    }

    .cta-onesection svg {
        margin-left: auto;
        vertical-align: middle;
        flex-shrink: 0;
    }

}