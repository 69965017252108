#bottom-footer {
    background-color: #f8f9fa;
    margin: 0 auto;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 1.25rem;
    display: grid;
    gap: 0;
    grid-template-columns: 33% 16% 16% 33%; /*
    i set the grid-template-columns to 33% 16% 16% 33% to match the layout on the live website.
    on production website, each section in the footer has a specific width: i set them this same way so we could match the alignment.
    the first and last sections are wider (33% each) because they hold "bigger" elements;
    the middle sections are narrower (16% each - "azienda" and "viaggia con noi") to fit the smaller content.
    */
    box-sizing: border-box;
}

#bottom-footer h2 { /* so all h2 are blue */
    color: #00359a;
    font-size: 1.10rem;
}

/* had to set some styles here because I noticed it had that standard purple-ish color when you visited the link */
/* M: link have (can have) different colors when you are over with the mouse or have been already visited, this is done with "pseudo classes":
   read: https://developer.mozilla.org/en-US/docs/Web/CSS/:visited and all other a related pseudo classes
   on pseudo classes you can also read: https://web.dev/learn/css/pseudo-classes?continue=https%3A%2F%2Fweb.dev%2Flearn%2Fcss%23article-https%3A%2F%2Fweb.dev%2Flearn%2Fcss%2Fpseudo-classes */
#bottom-footer a {
    color: inherit;
    text-decoration: none;
}

#bottom-footer a:hover {
    color: #00359a;
}

#bottom-footer ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

}

#bottom-footer ul li {
    line-height: 2rem;
}

/* Common styles for vagabondo info and links section */
.footer-section {
    padding: 1.25rem;
}

.footer-section h2 {
    /* margin-bottom: 1.25rem; */
}

/* logo and social media links */
#bottom-footer > div:first-child {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* button styles for social media icons */
#social-media {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: nowrap;
}

#social-media h2 {
    font-size: 1.10rem;
    text-transform: uppercase;
}


#social-media svg {
    fill: #00359a;
    width: 16px;
    height: 16px;
}

#social-media a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

#social-media a:hover {
    border-color: #00359a;
}

/* this is to make "Seguici" next to the SVG buttons.
 * I wasn't targeting directly the section and its child elements - that was the issue
 */

#social-media ul {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0;
    margin: 0;
    list-style-type: none;
    flex-wrap: nowrap;
}

#bottom-footer img {
    width: clamp(195px, 20vw, 260px);
    height: auto;
}

#contatti address, #contatti p {
    font-style: normal;
    margin: 0 0 0.5rem 0;
    line-height: 1.5;
}

/* this is for the "paesi" section */
#paesi ul {
    display: grid;

    /*
     * This sets the minimum width of each column, which means that
     * if the available width is less than 400px (plus the gap),
     * the columns will stack on top of each other
     */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0;
    max-width: calc(400px + 5rem); /*this calc basically ensures the grid will not exceed two columns.
    the 400px accounts for the two 200px columns, and 1rem is the gap between them (otherwise they would not be aligned correctly). */
    margin-right: auto;

    /* let me know if you think this approach is ok. from my understanding,
    using auto-fit and minmax allows the grid to automatically adjust
    the number of columns based on the available space - so it is more responsive now*/

    /* revised - from devtools I really don't see gaps so I think it should stay like this */
}

#paesi ul li {
    line-height: 2rem;
    white-space: nowrap;

}

/* copyright and footer bottom */
#copyright {
    grid-column: 1 / 4;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1.25rem 0;
}

#copyright h2, #copyright p:first-of-type {
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: normal;
    display: inline;
    color: #000;
    padding: 0;
    margin: 0;
}

#copyright p:last-of-type {
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    color: #000;
    width: 100%
    /*
   the white-space property above this section was causing the issue
   where the second <p> couldn't move to the next line
   if we apply width: 100% to the second <p>, it forces it
   to occupy the full width, which makes it start on the next line.
   not sure if this approach is right.
   */
}


/* for the bottom links */
#bottom-links {
    grid-column: 4 / 5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem 0;
    position: relative; /* this is to put the button outside */
}

.footer-button { /* class qui */
    position: absolute; /* to put the button outside the flow */
    right: -3rem; /* this value is to tinker the button closer or further from the right edge */
    top: 50%;
    transform: translateY(-50%); /* this is used for vertical alignment */
    display: inline-block;
}


#bottom-links a {
    font-size: 0.75rem;
    font-weight: bold; /* privacy policy is now bold */
}

#bottom-links svg {
    fill: #00359a;
    transition: fill 0.3s ease;
}

#bottom-links svg:hover {
    fill: #1b76ef;
}

.row-border {
    border-top: 1px solid #dddddd;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 1 / 5;
}
